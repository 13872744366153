export default {
  form: {
    name: 'Name*',
    surname: 'Surname*',
    email: 'E-mail*',
    company: 'Company*',
    agency: 'Agency*',
    phone: 'Telephone',
    portfolio: 'Portfolio Link',
    portfolioOptional: 'Other Link',
    fileUpload: 'Resume (PDF)',
    fileUploadTxt: 'UPLOAD YOUR RESUME',
    presentation: 'Presentation Text',
    message: 'Message*',
    privacyCaption:
      'Under and for the purposes of Articles 7, 12, 13, and following of EU Regulation 2016/679 - GDPR, I declare that I have read the <a href="https://www.iubenda.com/privacy-policy/57910588" target="_blank" class="link-underline-1">privacy policy</a> regarding the processing of my personal data. Your email address will be used solely to respond to the information request you are submitting.*',
    privacyError: 'Please accept terms and conditions',
    formContacts: {
      postinApiId: '9d9c1447-1687-417e-848d-aa9da23bfff4',
    },
    formCareers: {
      postinApiId: '3ff0b6df-183d-47c0-9254-19379e69f250',
    },
    newsletterCaption: 'Send me updates on the magic of CGI',
    submitCTA: 'SUBMIT REQUEST',
    genericError: 'Field not filled out correctly',
    successPageTitle: 'Thank you',
    successPageText:
      'The form has been successfully submitted, and you will be contacted by the team as soon as possible.',
    failurePageTitle: 'We are sorry',
    failurePageText:
      'There were errors in submitting the form. Please try again later.',
  },
  careers: {
    formCTA: 'Send request',
    formSubtitle: '<strong>Requirements and skills</strong>',
    formCaption:
      'If you think you are the right person for us, fill out the form and submit your application.',
    otherPositionsTitle: 'Other open positions',
    postinApiId: 'e2209090-6264-4d73-803b-dd85af69e909',
  },
  seoSuccess: {
    title: 'Request submitted',
  },
  seoFailure: {
    title: 'Error!',
  },
  newsletter: {
    successTitle: 'Welcome to our newsletter!',
    successText:
      'From today, you will be among the first to discover what’s behind the magic of Bluemotion.',
    seo: {
      title: 'Newsletter subscription completed',
    },
  },
  seo: {
    title: 'Contacts',
    description: 'Looking to reach out to Symmune? Discover how to contact us via phone or by filling out our online contact form.'
  },
}
