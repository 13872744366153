export default {
  404: {
    title: '404 Page not found',
    text: 'The page you are looking for is not available.<br /> Try going back to the homepage or try again later.',
    content: {
      title:
        "It seems that the page you were looking for no longer exists or has been moved.<br>Don't worry, we'll help you find your way.",
      pre: 'Here are some things you can do:',
      home: {
        label: 'Return to Homepage',
        text: ': start from scratch and explore all the sections of our site.',
      },
      contact: {
        label: 'Contact us',
        text: ': if you need assistance, don’t hesitate to reach out.',
      },
      expertise: {
        label: 'Discover our Expertise',
        text: ': the 3D world can revolutionize your communication.',
      },
      blog: {
        label: 'Read our articles',
        text: ': news and curiosities from the world of three dimensions.',
      },
      studio: {
        label: 'The studio',
        text: ': learn more about who we are and our philosophy.',
      },
    },
  },
  500: {
    title: '500 Internal Server Error',
    text: 'Sorry, an internal error has occurred. <br />We are working to fix the issue as soon as possible. <br /> Try going back to the homepage or try again later.',
  },
}
