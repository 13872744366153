export default {
  'menu-nav': {
    menu: 'Menu',
    works: 'Works',
    expertise: 'Expertise',
    expertises: 'All expertises',
    technology: 'Technology',
    technologies: 'All technologies',
    studio: 'Studio',
    blog: 'B.log',
    contacts: 'Contact',
    contents: 'Contents',
    solutions: 'Solutions',
    industries: 'Industries',
    'clinical-areas': 'Clinical Areas',
    'about-us': 'About Us',
    back: 'Back',
    all: 'Discover all',
    research: 'Research',
    lab: 'Lab',
    team: 'Team',
    publications: 'Publications',
    news: 'News',
    contacts: 'Get in touch',
    ref: 'Giulia Pasqual Associate Professor <br>Department Of Surgery Oncology and Gastroenterology',
    /*links: [
      {
        label: 'Works',
        route: { name: 'projects' }
      },
      {
        label: 'Expertise',
        route: { name: 'expertise' }
      },
      {
        label: 'Technology',
        route: { name: 'technology' }
      },
      {
        label: 'Studio',
        route: { name: 'studio' }
      },
      {
        label: 'B.log',
        route: { name: 'blog' }
      },
      {
        label: 'Contact',
        route: { name: 'contacts' }
      },
    ]*/
  },
}
